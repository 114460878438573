$yellow: #ffdc00ff;
$grey: #636b70ff;
$greyLight: #efefef;
$green: #a4b700ff;
$blue: #00a7e3ff;
$orange: #f08400ff;
$red: #ce2a1d;
$black: black;
$white: white;

$transitionBg: 0.3s;
$transitionColor: 0.15s;
$transitionTransform: 0.35s;
