@import '../../resources/scss/vars';

.sub-header {
	overflow: hidden;
	background-color: $yellow;
	border-radius: 16px;
	position: relative;

	@media screen and (min-width: 769px) {
		border-radius: 20px;
	}

	&__inner {
		width: 100%;
	}

	&__text {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 72px;
		text-align: center;
		width: 100%;

		@media screen and (min-width: 1025px) {
			margin: 0;
			justify-content: flex-start;
			text-transform: uppercase;
			text-align: left;
		}

		@media screen and (min-width: 1281px) {
			min-height: 110px;
		}
	}

	&__content {
		@media screen and (min-width: 1025px) {
			position: relative;
			z-index: 1;
			padding: 10px 10px 10px 194 / 1736 * 100%;
			background: linear-gradient(90deg, $yellow 25%, rgba($yellow, 0) 80%);
		}
	}

	&__bg {
		display: none;

		@media screen and (min-width: 1025px) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
		}
	}
}
