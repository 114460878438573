#root {
	min-height: 100vh;
}

.body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	main {
		flex: 1;
	}
}
