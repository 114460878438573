@import '../../resources/scss/vars';

.lang {
	$this: &;

	line-height: 1;
	font-size: 12px;
	transition: border $transitionTransform;
	border-radius: 6px 6px 0 0;
	position: relative;
	z-index: 10;
	box-shadow: none;
	background-color: $white;
	border-style: solid;
	border-color: $white;
	border-width: 1px 1px 0 1px;
	padding: 6px 0 0;

	@media screen and (min-width: 769px) {
		font-size: 18px;
		border-radius: 12px 12px 0 0;
	}

	&--open {
		border-radius: 6px 6px 0 0;
		border-color: $grey;

		@media screen and (min-width: 769px) {
			border-radius: 12px 12px 0 0;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 6px 12px;
		border-radius: 6px;
		cursor: pointer;
		text-transform: uppercase;
		text-decoration: none;
		color: $black;
		transition:
			color $transitionColor,
			border-radius $transitionTransform,
			border $transitionTransform;

		@media screen and (min-width: 769px) {
			border-radius: 12px;
		}

		@media (hover: hover) {
			&:hover {
				#{$this}__dropdown & {
					color: $yellow;

					#{$this}__flag {
						transform: scale(1.1);
					}
				}
			}
		}
	}

	&__flag {
		width: 18px;
		height: 12px;
		display: block;
		margin-right: 6px;
		transition: transform $transitionTransform;

		@media screen and (min-width: 769px) {
			width: 30px;
			height: 20px;
			margin-right: 12px;
		}
	}

	&__dropdown {
		position: absolute;
		left: -1px;
		top: 100%;
		width: calc(100% + 2px);
		opacity: 0;
		visibility: hidden;
		display: flex;
		flex-direction: column;
		border-radius: 0 0 6px 6px;
		border-style: solid;
		border-color: $grey;
		border-width: 0 1px 1px 1px;
		background-color: $white;
		padding: 0 0 6px;
		transition:
			opacity $transitionTransform,
			visibility $transitionTransform;

		@media screen and (min-width: 769px) {
			border-radius: 0 0 12px 12px;
		}

		#{$this}--open & {
			opacity: 1;
			visibility: visible;
		}
	}
}
