.header {
	padding: 10px 0 10px;
	margin-bottom: 10px;

	@media screen and (min-width: 1025px) {
		margin-bottom: 20px;
	}

	@media screen and (min-width: 1920px) {
		margin-bottom: 30px;
		padding: 30px 0 10px;
	}


	&__inner {
		display: flex;
		align-items: flex-end;
	}

	&__logo {
		display: block;
		max-width: 200px;
		margin-right: auto;

		@media screen and (min-width: 1025px) {
			max-width: 300px;
		}


		@media screen and (min-width: 1920px) {
			max-width: 406px;
		}

		img {
			display: block;
			width: 100%;
		}
	}

	&__lang {
		margin-left: 24px;
	}
}
