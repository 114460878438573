@import '../../resources/scss/vars';

.info {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 28px 0 60px;

	@media screen and (min-width: 769px) {
		padding: 52px 0 100px;
	}

	&__title {
		text-align: left;

		span {
			display: block;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		@media screen and (min-width: 769px) {
			text-align: center;

			span {
				display: inline;
				margin: 0;
			}
		}
	}

	&__body {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__table {
		width: 100%;
		max-width: 980px;
		display: flex;
		flex-wrap: wrap;
		margin: 24px auto 0;

		@media screen and (min-width: 769px) {
			display: block;
			border-radius: 12px;
			border: 1px solid $greyLight;
			margin: 76px auto 0;

		}

		&-row {
			width: 50%;
			display: flex;
			flex-direction: column;

			@media screen and (min-width: 769px) {
				width: 100%;
				flex-direction: row;
			}

			&:not(:last-child) {
				margin: 0 0 8px;
				padding: 0 0 8px;
				border-bottom: 1px solid $greyLight;

				@media screen and (min-width: 769px) {
					margin: 0;
					padding: 0;
				}
			}
		}

		&-cell {
			line-height: 22px;
			width: 100%;

			@media screen and (min-width: 769px) {
				font-size: 24px;
				padding: 17px 40px;
			}

			&:first-child {
				flex-shrink: 0;
				margin-bottom: 12px;
				font-size: 14px;

				@media screen and (min-width: 769px) {
					font-size: 24px;
					font-weight: bold;
					width: 430 / 980 * 100%;
					margin: 0;
					border-right: 1px solid $greyLight;
				}
			}

			&:last-child {
				font-weight: bold;
				font-size: 20px;

				@media screen and (min-width: 769px) {
					font-weight: normal;
					font-size: 24px;
				}
			}
		}
	}

	&__actions {
		padding: 12px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -12px;
		margin-bottom: -12px;
		flex-wrap: wrap;
		flex-direction: row;


		@media screen and (min-width: 769px) {
			flex-direction: row;
		}

		&-cell {
			padding-left: 12px;
			margin-bottom: 12px;
			width: 100%;
			max-width: 280px;

			@media screen and (min-width: 769px) {
				width: auto;
				max-width: none;
			}
		}
	}

	.error {
		color: $red;
		font-size: 20px;
		text-align: center;
		margin-top: 20px;
	}

	.button {
		text-align: center;
		width: 100%;
		display: block;
	}

	.loading {
		margin: 0 auto;
	}
}
