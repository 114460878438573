@import '../../resources/scss/vars';

.captcha {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 12px 0 12px;

	@media screen and (min-width: 768px) {
		padding: 30px 0 40px;
	}

	@media screen and (min-width: 1366px) {
		padding: 40px 0 40px;
	}

	@media screen and (min-width: 1920px) {
		padding: 40px 0 60px;
	}

	&__grid {
		display: flex;
		margin-left: -20px;
		margin-bottom: -20px;
		flex-wrap: wrap;

		@media screen and (min-width: 1025px) {
			margin-left: -40px;
			margin-bottom: -40px;
		}
	}

	&__top {
		margin-bottom: 60px;

		@media screen and (min-width: 769px) {
			margin-bottom: 80px;
		}

		@media screen and (min-width: 1025px) {
			margin-bottom: 100px;
		}

		@media screen and (min-width: 1281px) {
			margin-bottom: 128px;
		}
	}

	&__cell {
		padding-left: 20px;
		margin-bottom: 20px;
		width: 100%;

		@media screen and (min-width: 768px) {
			width: 50%;
		}

		@media screen and (min-width: 1025px) {
			padding-left: 40px;
			margin-bottom: 40px;
		}

		.h3:last-child {
			padding-top: 20px;

			@media screen and (min-width: 769px) {
				padding-top: 18px;
			}
		}
	}

	&__body {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 30px 0 0;

		@media screen and (min-width: 1366px) {
			padding: 40px 0 0;
		}

		@media screen and (min-width: 1920px) {
			padding: 60px 0 0;
		}

		form {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		.button {
			width: max-content;
			margin-top: 20px;

			@media screen and (min-width: 1920px) {
				margin-top: 40px;
			}
		}
	}

	&__invalidate-url {
		color: $red;
	}

	.error {
		color: $red;
		font-size: 18px;
		text-align: center;
		margin-top: 20px;
	}
}
