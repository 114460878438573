.container {
	width: 100%;
	max-width: calc(1736px + 36px);
	padding: 0 18px;
	margin: 0 auto;

	&--first {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
}
