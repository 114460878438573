.h1 {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 500;

	@media screen and (min-width: 769px) {
		font-size: 30px;
	}

	@media screen and (min-width: 1025px) {
		font-size: 40px;
	}

	@media screen and (min-width: 1366px) {
		font-size: 50px;
	}

	@media screen and (min-width: 1920px) {
		font-size: 72px;
	}
}

.h2 {
	font-size: 18px;
	line-height: 1.375;

	@media screen and (min-width: 769px) {
		font-size: 20px;
	}

	@media screen and (min-width: 1025px) {
		font-size: 22px;
	}

	@media screen and (min-width: 1366px) {
		font-size: 32px;
	}

	@media screen and (min-width: 1920px) {
		font-size: 40px;
	}
}

.h3 {
	font-size: 16px;
	line-height: 1.375;

	@media screen and (min-width: 769px) {
		font-size: 18px;
	}

	@media screen and (min-width: 1025px) {
		font-size: 20px;
	}

	@media screen and (min-width: 1366px) {
		font-size: 22px;
	}

	@media screen and (min-width: 1920px) {
		font-size: 32px;
	}
}
