@import '../../resources/scss/vars';

.not-genuine {
    padding: 28px 0 60px;

    @media screen and (min-width: 769px) {
        padding: 40px 0 80px;
    }

    @media screen and (min-width: 1028px) {
        padding: 60px 0 100px;
    }

    @media screen and (min-width: 1281px) {
        padding: 88px 0 120px;
    }

    &__grid {
        display: flex;
        margin-left: -20px;
        margin-bottom: -20px;
        flex-wrap: nowrap;

        @media screen and (min-width: 1025px) {
            margin-left: -40px;
            margin-bottom: -40px;
        }
    }
    &__cell {
        padding-left: 20px;
        margin-bottom: 20px;

        &:first-child {
            width: 100%;
        }

        &:last-child {
            display: none;

            @media screen and (min-width: 1025px) {
                display: block;
                min-width: 420px;
                text-align: center;
            }
        }

        @media screen and (min-width: 1025px) {
            padding-left: 40px;
            margin-bottom: 40px;
        }

        .h3:last-child {
            padding-top: 20px;

            @media screen and (min-width: 769px) {
                padding-top: 18px;
            }
        }
    }
}
