@import '../../resources/scss/vars';

.footer {
	position: sticky;
	bottom: 0;
	padding: 0 0 12px 0;

	@media screen and (min-width: 1920px) {
		padding: 0 0 40px 0;
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: center;
		flex-direction: column;
		border-radius: 16px;
		background-color: $yellow;
		padding: 12px;

		@media screen and (min-width: 769px) {
			padding: 20px 40px;
			border-radius: 20px;
			flex-direction: row;
		}

		@media screen and (min-width: 1920px) {
			padding: 20px 180px 20px 108px;
		}
	}

	&__left {
		display: none;

		@media screen and (min-width: 1281px) {
			display: block;
			margin-right: auto;
		}
	}

	&__logo {
		display: block;
		max-width: 320px;
		margin-bottom: 12px;
	}

	&__rights {
		font-size: 16px;
		line-height: 20px;
	}

	&__contacts {
		margin: 12px auto 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@media screen and (min-width: 386px) {
			flex-direction: row;
		}

		@media screen and (min-width: 769px) {
			margin: 0 0 0 auto;
			display: block;
		}

		@media screen and (min-width: 1025px) {
			width: auto;
			min-width: 372px;
		}

		&-item {
			text-decoration: none;
			font-size: 12px;
			line-height: 1.3;
			color: $black;
			display: block;

			@media screen and (min-width: 769px) {
				font-size: 28px;
				line-height: 1.3;
			}

			&:not(:last-child) {
				margin: 0 0 12px 0;

				@media screen and (min-width: 386px) {
					margin: 0 12px 0 0;
				}

				@media screen and (min-width: 769px) {
					margin: 0 0 10px 0;
				}
			}
		}
	}

	&__socials {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 300px;

		@media screen and (min-width: 769px) {
			margin: 0 20px;
		}

		&-item {
			display: flex;
			width: 26px;
			height: 26px;

			@media screen and (min-width: 769px) {
				width: 45px;
				height: 45px;
			}

			&:not(:last-child) {
				margin-right: 32px;

				@media screen and (min-width: 1366px) {
					margin-right: 56px;
				}
			}

			img {
				display: block;
			}
		}
	}
}
