.error-container {
    padding: 28px 0 60px;

    @media screen and (min-width: 769px) {
        padding: 40px 0 80px;
    }

    @media screen and (min-width: 1028px) {
        padding: 60px 0 100px;
    }

    @media screen and (min-width: 1281px) {
        padding: 88px 0 120px;
    }
}
