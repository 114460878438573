.loading {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.loading:after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $yellow;
    border-color: $yellow transparent $yellow transparent;
    animation: loading 1.2s linear infinite;
}


@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
