@import '../../resources/scss/vars';

button {
	appearance: none;
	background: transparent;
	border: none;
	box-shadow: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	text-decoration: none;
	user-select: none;
}

.button {
	display: flex;
	padding: 10px 24px;
	background-color: $yellow;
	color: $black;
	transition: all $transitionColor;
	border-radius: 12px;
	font-size: 18px;
	line-height: 1;
	text-decoration: none;

	&--black {
		background-color: $black;
		color: $yellow;

		@media (hover: hover) {
			&:hover {
				color: $black;
				background-color: $yellow;
			}
		}
	}

	&--loading {
		opacity: 0.5;
		pointer-events: none;
	}

	@media screen and (min-width: 1025px) {
		padding: 18px 36px;
		border-radius: 18px;
		font-size: 24px;
		line-height: 22px;
	}

	@media (hover: hover) {
		&:hover {
			color: $yellow;
			background-color: $black;
		}
	}
}
